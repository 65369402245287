import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const SitePageBanners = MatxLoadable({
  loader: () => import("./SitePageBanners")
})

const sitePagesRoutes = [
  {
    path: "/pageBanners",
    component: SitePageBanners,
    auth: authRoles.admin
  }
];

export default sitePagesRoutes;
 