import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const ApplicantList = MatxLoadable({
  loader: () => import("./Applicants")
})

const applicantListRoutes = [
  {
    path: "/applicantList",
    component: ApplicantList,
    auth: authRoles.admin
  }
];

export default applicantListRoutes;
 