import axios from 'axios';
export const GET_APPLICANTS_LIST_FAILURE = 'GET_APPLICANTS_LIST_FAILURE';
export const GET_APPLICANTS_LIST = 'GET_APPLICANTS_LIST';
export const GET_APPLICANTS_LIST_SUCCESS = 'GET_APPLICANTS_LIST_SUCCESS';
export const SET_APPLICANTS_LOADING = 'SET_APPLICANTS_LOADING';
export const GET_APPLICANTS_DATA_SUCCESS = ' GET_APPLICANTS_DATA_SUCCESS';
export const GET_APPLICANTS_SECTION = 'GET_APPLICANTS_SECTION';
export const GET_APPLICANTS_DATA_FAILURE = 'GET_APPLICANTS_DATA_FAILURE';
export const UPDATE_APPLICANTS_SECTION = 'UPDATE_APPLICANTS_SECTION';
export const CREATE_APPLICANTS_DATA_SUCCESS = 'CREATE_APPLICANTS_DATA_SUCCESS';
export const UPDATE_APPLICANTS_DATA_SUCCESS = 'UPDATE_APPLICANTS_DATA_SUCCESS';
export const CREATE_APPLICANTS_DATA_FAILURE = 'CREATE_APPLICANTS_DATA_FAILURE';
export const UPLOAD_FILE_DATA_FAILURE = 'UPLOAD_FILE_DATA_FAILURE';
export const UPLOAD_FILE_DATA_SUCCESS = 'UPLOAD_FILE_DATA_SUCCESS';

export const getResumeist = () => {
    return (dispatch) => {
      dispatch({
        type: GET_APPLICANTS_LIST
      });
      dispatch({
        type: SET_APPLICANTS_LOADING,
        data: true
      });
      axios
        .get(`/api/ResumeList` ,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: GET_APPLICANTS_LIST_SUCCESS,
            data: res.data,
  
          });
  
          dispatch({
            type: SET_APPLICANTS_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_APPLICANTS_LIST_FAILURE,
          });
  
          dispatch({
            type: SET_APPLICANTS_LOADING,
            data: false
          });
        });
    };
  };

  
  export const addJob = (body, history) => {
   
    return (dispatch) => {
      dispatch({
        type: SET_APPLICANTS_LOADING,
        data: true
      });
      axios
        .post(`/api/admin/createJob`, body,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: CREATE_APPLICANTS_DATA_SUCCESS,
            data: res.data,
  
          });
          history.push('/jobList');
  
          dispatch({
            type: SET_APPLICANTS_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: CREATE_APPLICANTS_DATA_FAILURE,
          });
  
          dispatch({
            type: SET_APPLICANTS_LOADING,
            data: false
          });
        });
    };
  };


