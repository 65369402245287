import {
    GET_ADDRESS_LIST_SUCCESS,
    SET_ADDRESS_LOADING,
    GET_ADDRESS_DATA_SUCCESS,
    UPDATE_ADDRESS_DATA_SUCCESS
} from '../actions/siteAddressesActions';

const initialState = {
    redirect: false,
    loading: false,
    isSuccess:false,
    addressList: [],
    ADDRESSectionData: {}
}

const addressReducers = (state = initialState, action) => {

    switch (action.type) {
        case GET_ADDRESS_LIST_SUCCESS:
            return Object.assign({}, state, {
                addressList: action.data
            })
        case SET_ADDRESS_LOADING:
            return Object.assign({}, state, {
                loading: action.data
            });
        case UPDATE_ADDRESS_DATA_SUCCESS:
            return Object.assign({}, state, {
                isSuccess: action.data
            });

        default:
            return state;
    }
}

export default addressReducers;