import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const SiteAddresses = MatxLoadable({
  loader: () => import("./SiteAddress")
})

const siteAddressesRoutes = [
  {
    path: "/officeAddresses",
    component: SiteAddresses,
    auth: authRoles.admin
  }
];

export default siteAddressesRoutes;
 