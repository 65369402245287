import {
    GET_SITE_LIST_SUCCESS,
    SET_SITE_LOADING,
    GET_SITE_DATA_SUCCESS,
    UPDATE_SITE_DATA_SUCCESS
} from '../actions/siteSettingsActions';

const initialState = {
    redirect: false,
    loading: false,
    isSuccess:false,
    siteData: {},
}

const SiteSettingReducers = (state = initialState, action) => {

    switch (action.type) {
        case GET_SITE_LIST_SUCCESS:
            return Object.assign({}, state, {
                siteData: action.data
            })
        case SET_SITE_LOADING:
            return Object.assign({}, state, {
                loading: action.data
            });
        case UPDATE_SITE_DATA_SUCCESS:
            return Object.assign({}, state, {
                isSuccess: action.data
            });

        default:
            return state;
    }
}

export default SiteSettingReducers;