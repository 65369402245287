import {
    GET_JOB_LIST_SUCCESS,
    SET_JOB_LOADING,
    GET_JOB_DATA_SUCCESS,
    CREATE_JOB_DATA_SUCCESS
} from '../actions/JobListAction';

const initialState = {
    redirect: false,
    loading: false,
    isSuccess:false,
    jobsList: [],
    pageSectionData: {}
}

const jobReducers = (state = initialState, action) => {

    switch (action.type) {
        case GET_JOB_LIST_SUCCESS:
            return Object.assign({}, state, {
                jobsList: action.data
            })
        case GET_JOB_DATA_SUCCESS:
            return Object.assign({}, state, {
                pageSectionData: action.data
            })
        case SET_JOB_LOADING:
            return Object.assign({}, state, {
                loading: action.data
            });
        case CREATE_JOB_DATA_SUCCESS:
            return Object.assign({}, state, {
                isSuccess: action.data
            });

        default:
            return state;
    }
}

export default jobReducers;