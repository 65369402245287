import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const EditSiteSettings = MatxLoadable({
  loader: () => import("./EditSiteSettings")
})

const EditSiteSettingsRoutes = [
  {
    path: "/editSiteSettings",
    component: EditSiteSettings,
    auth: authRoles.admin
  }
];

export default EditSiteSettingsRoutes;
 