import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const JobList = MatxLoadable({
  loader: () => import("./Jobs")
})

const jobListRoutes = [
  {
    path: "/jobList",
    component: JobList,
    auth: authRoles.admin
  }
];

export default jobListRoutes;
 