import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const EditBanners = MatxLoadable({
  loader: () => import("./EditBanners")
})

const EditViewPageSectionRoutes = [
  {
    path: "/editPageBanner",
    component: EditBanners,
    auth: authRoles.admin
  }
];

export default EditViewPageSectionRoutes;
 