import axios from 'axios';
export const GET_JOB_LIST_FAILURE = 'GET_JOB_LIST_FAILURE';
export const GET_JOB_LIST = 'GET_JOB_LIST';
export const GET_JOB_LIST_SUCCESS = 'GET_JOB_LIST_SUCCESS';
export const SET_JOB_LOADING = 'SET_JOB_LOADING';
export const GET_JOB_DATA_SUCCESS = ' GET_JOB_DATA_SUCCESS';
export const GET_JOB_SECTION = 'GET_JOB_SECTION';
export const GET_JOB_DATA_FAILURE = 'GET_JOB_DATA_FAILURE';
export const UPDATE_JOB_SECTION = 'UPDATE_JOB_SECTION';
export const CREATE_JOB_DATA_SUCCESS = 'CREATE_JOB_DATA_SUCCESS';
export const UPDATE_JOB_DATA_SUCCESS = 'UPDATE_JOB_DATA_SUCCESS';
export const CREATE_JOB_DATA_FAILURE = 'CREATE_JOB_DATA_FAILURE';
export const UPLOAD_FILE_DATA_FAILURE = 'UPLOAD_FILE_DATA_FAILURE';
export const UPLOAD_FILE_DATA_SUCCESS = 'UPLOAD_FILE_DATA_SUCCESS';

export const getJobList = () => {
    return (dispatch) => {
      dispatch({
        type: GET_JOB_LIST
      });
      dispatch({
        type: SET_JOB_LOADING,
        data: true
      });
      axios
        .get(`api/jobList` ,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: GET_JOB_LIST_SUCCESS,
            data: res.data,
  
          });
  
          dispatch({
            type: SET_JOB_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_JOB_LIST_FAILURE,
          });
  
          dispatch({
            type: SET_JOB_LOADING,
            data: false
          });
        });
    };
  };

  
  export const addJob = (body, history) => {
   
    return (dispatch) => {
      dispatch({
        type: SET_JOB_LOADING,
        data: true
      });
      axios
        .post(`/api/admin/createJob`, body,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: CREATE_JOB_DATA_SUCCESS,
            data: res.data,
  
          });
          history.push('/jobList');
  
          dispatch({
            type: SET_JOB_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: CREATE_JOB_DATA_FAILURE,
          });
  
          dispatch({
            type: SET_JOB_LOADING,
            data: false
          });
        });
    };
  };


  export const updateJob = (body, history) => {
   
    return (dispatch) => {
      dispatch({
        type: SET_JOB_LOADING,
        data: true
      });
      axios
        .post(`/api/admin/updateJob`, body,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: CREATE_JOB_DATA_SUCCESS,
            data: res.data,
  
          });
          history.push('/jobList');
  
          dispatch({
            type: SET_JOB_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: CREATE_JOB_DATA_FAILURE,
          });
  
          dispatch({
            type: SET_JOB_LOADING,
            data: false
          });
        });
    };
  };
