import {
    GET_MEDIA_LIST_SUCCESS,
    SET_MEDIA_LOADING,
    GET_MEDIA_DATA_SUCCESS,
    UPDATE_MEDIA_DATA_SUCCESS
} from '../actions/socialMediaActions';

const initialState = {
    redirect: false,
    loading: false,
    isSuccess:false,
    mediaList: [],
    MEDIAectionData: {}
}

const socialMediaReducers = (state = initialState, action) => {

    switch (action.type) {
        case GET_MEDIA_LIST_SUCCESS:
            return Object.assign({}, state, {
                mediaList: action.data
            })
        case SET_MEDIA_LOADING:
            return Object.assign({}, state, {
                loading: action.data
            });
        case UPDATE_MEDIA_DATA_SUCCESS:
            return Object.assign({}, state, {
                isSuccess: action.data
            });

        default:
            return state;
    }
}

export default socialMediaReducers;