import axios from 'axios';
export const GET_MEDIA_LIST_FAILURE = 'GET_MEDIA_LIST_FAILURE';
export const GET_MEDIA_LIST = 'GET_MEDIA_LIST';
export const GET_MEDIA_LIST_SUCCESS = 'GET_MEDIA_LIST_SUCCESS';
export const SET_MEDIA_LOADING = 'SET_MEDIA_LOADING';
export const GET_MEDIA_DATA_SUCCESS = ' GET_MEDIA_DATA_SUCCESS';
export const GET_MEDIA_SECTION = 'GET_MEDIA_SECTION';
export const GET_MEDIA_DATA_FAILURE = 'GET_MEDIA_DATA_FAILURE';
export const UPDATE_MEDIA_SECTION = 'UPDATE_MEDIA_SECTION';
export const CREATE_MEDIA_DATA_SUCCESS = 'CREATE_MEDIA_DATA_SUCCESS';
export const UPDATE_MEDIA_DATA_SUCCESS = 'UPDATE_MEDIA_DATA_SUCCESS';
export const UPDATE_MEDIA_DATA_FAILURE = 'UPDATE_MEDIA_DATA_FAILURE';
export const UPLOAD_FILE_DATA_FAILURE = 'UPLOAD_FILE_DATA_FAILURE';
export const UPLOAD_FILE_DATA_SUCCESS = 'UPLOAD_FILE_DATA_SUCCESS';

export const getMediaList = () => {
    return (dispatch) => {
      dispatch({
        type: GET_MEDIA_LIST
      });
      dispatch({
        type: SET_MEDIA_LOADING,
        data: true
      });
      axios
        .get(`api/admin/SocialMediaList` ,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: GET_MEDIA_LIST_SUCCESS,
            data: res.data,
  
          });
  
          dispatch({
            type: SET_MEDIA_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_MEDIA_LIST_FAILURE,
          });
  
          dispatch({
            type: SET_MEDIA_LOADING,
            data: false
          });
        });
    };
  };

 

  export const updateMedia = (body, history) => {
    return (dispatch) => {
      dispatch({
        type: UPDATE_MEDIA_SECTION
      });
      dispatch({
        type: SET_MEDIA_LOADING,
        data: true
      });
      axios
        .post(`api/admin/updateMedia`, body,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: UPDATE_MEDIA_DATA_SUCCESS,
            data:true           
  
          });
          history.push('/socialMediaPagaes');
          dispatch({
            type: SET_MEDIA_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_MEDIA_DATA_FAILURE,
          });
  
          dispatch({
            type: SET_MEDIA_LOADING,
            data: false
          });
        });
    };
  };
