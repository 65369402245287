import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const EditSocialMedia = MatxLoadable({
  loader: () => import("./EditSocialMedia")
})

const editsocialmediaPagesRoutes = [
  {
    path: "/editSocialMedia",
    component: EditSocialMedia,
    auth: authRoles.admin
  }
];

export default editsocialmediaPagesRoutes;
 