import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const SocialMediaList = MatxLoadable({
  loader: () => import("./SocialMediaList")
})

const SocialMediaListRoutes = [
  {
    path: "/socialMediaPagaes",
    component: SocialMediaList,
    auth: authRoles.admin
  }
];

export default SocialMediaListRoutes;
 