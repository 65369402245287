import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const EditAddress = MatxLoadable({
  loader: () => import("./EditAddress")
})

const EditAddressRoutes = [
  {
    path: "/editAddress",
    component: EditAddress,
    auth: authRoles.admin
  }
];

export default EditAddressRoutes;
 