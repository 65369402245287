import axios from 'axios';
export const GET_SITE_LIST_FAILURE = 'GET_SITE_LIST_FAILURE';
export const GET_SITE_LIST = 'GET_SITE_LIST';
export const GET_SITE_LIST_SUCCESS = 'GET_SITE_LIST_SUCCESS';
export const SET_SITE_LOADING = 'SET_SITE_LOADING';
export const GET_SITE_DATA_SUCCESS = ' GET_SITE_DATA_SUCCESS';
export const GET_SITE_SECTION = 'GET_SITE_SECTION';
export const GET_SITE_DATA_FAILURE = 'GET_SITE_DATA_FAILURE';
export const UPDATE_SITE_SECTION = 'UPDATE_SITE_SECTION';
export const CREATE_SITE_DATA_SUCCESS = 'CREATE_SITE_DATA_SUCCESS';
export const UPDATE_SITE_DATA_SUCCESS = 'UPDATE_SITE_DATA_SUCCESS';
export const UPDATE_SITE_DATA_FAILURE = 'UPDATE_SITE_DATA_FAILURE';
export const UPLOAD_FILE_DATA_FAILURE = 'UPLOAD_FILE_DATA_FAILURE';
export const UPLOAD_FILE_DATA_SUCCESS = 'UPLOAD_FILE_DATA_SUCCESS';

export const getSiteData = () => {
    return (dispatch) => {
      dispatch({
        type: GET_SITE_LIST
      });
      dispatch({
        type: SET_SITE_LOADING,
        data: true
      });
      axios
        .get(`api/admin/siteSettingList` ,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: GET_SITE_LIST_SUCCESS,
            data: res.data,
  
          });
  
          dispatch({
            type: SET_SITE_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_SITE_LIST_FAILURE,
          });
  
          dispatch({
            type: SET_SITE_LOADING,
            data: false
          });
        });
    };
  };




  export const updateData = (body, history) => {
    return (dispatch) => {
      dispatch({
        type: UPDATE_SITE_SECTION
      });
      dispatch({
        type: SET_SITE_LOADING,
        data: true
      });
      axios
        .post(`api/admin/updateSiteSettings`, body,{
        headers:   {
            'x-access-token':localStorage.getItem('token'),     
        }

        })
        .then((res) => {
          dispatch({
            type: UPDATE_SITE_DATA_SUCCESS,
           
  
          });
          history.push('/dashboard');
          dispatch({
            type: SET_SITE_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_SITE_DATA_FAILURE,
          });
  
          dispatch({
            type: SET_SITE_LOADING,
            data: false
          });
        });
    };
  }; 


  export const uploadFile = (body) => {
    return (dispatch) => {
      dispatch({
        type: UPDATE_SITE_SECTION
      });
      dispatch({
        type: SET_SITE_LOADING,
        data: true
      });
      axios
        .post('https://www.icvglobal.com/upload.php', body,{
        headers:   {
          'content-type': 'multipart/form-data'    
        }

        })
        .then((res) => {
          dispatch({
            type: UPLOAD_FILE_DATA_SUCCESS,
           
  
          });
          dispatch({
            type: SET_SITE_LOADING,
            data: false
          });
        })
        .catch((err) => {
          dispatch({
            type: UPLOAD_FILE_DATA_FAILURE,
          });
  
          dispatch({
            type: SET_SITE_LOADING,
            data: false
          });
        });
    };
  };